<template>
  <div class="container">
    <div class="relative max-w-md lg:max-w-5xl mx-auto px-7 py-[60px] pb-[50px] lg:pt-16 lg:pb-[150px]">
      <!-- <button type="button" class="hidden lg:block ml-auto mb-5 py-2 px-3 rounded-xl bg-primary hover:bg-primary-hover"
        @click="addNewCard">
        <div class="text-dark font-semibold">
          {{ $t('card.list.button.add-card') }}
        </div>
      </button> -->
      <div class="card-list">
        <div class="">
          <div v-if="cardsNotActivated.length" class="">
            <card-item-not-activated
              v-for="cardItem of cardsNotActivated"
              :key="cardItem.id"
              :card="cardItem"
            />
          </div>
          <div v-if="cardsActivated.length" class="">
            <card-item-activated
              v-for="cardItem of cardsActivated"
              :key="cardItem.id"
              :card="cardItem"
            />
          </div>
          <div v-if="cardsExpired.length" class="">
            <card-item-activated
              v-for="cardItem of cardsExpired"
              :key="cardItem.id"
              :card="cardItem"
            />
          </div>
          <div v-if="cardsSuspended.length" class="">
            <card-item-activated
              v-for="cardItem of cardsSuspended"
              :key="cardItem.id"
              :card="cardItem"
            />
          </div>
          <div>
            <card-item-add-new
              v-for="product of CARD_PRODUCTS"
              :key="product.id"
              :name="product.name"
              @click.native="addNewCard(product)"
            />
          </div>
        </div>
      </div>
      <available-card-count />
      <invitation-code />
      <!-- <button type="button" class="block lg:hidden sticky w-20 h-20 bottom-6 right-7 -mt-16 -mb-6 xs:-mr-10 sm:-mr-[6rem] md:-mr-40 ml-auto rounded-full bg-primary"
        @click="addNewCard">
        <img class="block mb-[1px] mx-auto" src="@/assets/images/icons/new-card-normal-mobile.svg" alt="add">
        <div class="text-dark text-xs leading-[14px] w-14 mx-auto font-semibold">
          {{ $t('card.list.button.add-card') }}
        </div>
      </button> -->
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CardItemActivated from '@/components/CardItemActivated.vue'
import CardItemNotActivated from '@/components/CardItemNotActivated.vue'
import CardItemAddNew from '@/components/CardItemAddNew.vue'
import InvitationCode from '@/components/InvitationCode.vue'
import AvailableCardCount from '@/components/AvailableCardCount.vue'
import Log from '@/utils/log'

export default {
  name: 'CardList',
  components: {
    CardItemActivated,
    CardItemNotActivated,
    CardItemAddNew,
    InvitationCode,
    AvailableCardCount
  },
  data () {
    return {
      activeTab: 'activated'
    }
  },
  computed: {
    ...mapGetters('WALLET', [
      'IS_LOGIN'
    ]),
    ...mapGetters('CARD', [
      // 'CARD_NEW',
      // 'CARD_PENDING',
      // 'CARD_ACTIVE',
      // 'CARD_DETAIL',
      // 'CARD_WAITING_TO_GET',
      // 'SEPARATED_CARD_NUMBER',
      'HAS_CARDS',
      'CARDS',
      'ORDERS',
      'LEFT_COUNT',
      'CARD_PRODUCTS'
    ]),
    cardsActivated () {
      return this.CARDS.filter(item => {
        return ['active', 'waiting to get'].includes(item.status) && !item.expired
      })
    },
    cardsNotActivated () {
      return this.ORDERS
    },
    cardsExpired () {
      return this.CARDS.filter(item => item.expired)
    },
    cardsSuspended () {
      return this.CARDS.filter(item => {
        return item.status === 'suspended' && !item.expired
      })
    }
  },
  watch: {
    cardsNotActivated: {
      immediate: true,
      handler (val) {
        if (val.length) {
          this.activeTab = 'notActivated'
        }
      }
    }
  },
  created () {
    this.$store.commit('CARD/SET_CARD_PRODUCT', null)
  },
  methods: {
    addNewCard (product) {
      Log('add new card clicked')
      if (this.LEFT_COUNT < 1) {
        this.showNotEligible()
      } else {
        this.showAddNewCardConfirm(product)
      }
    },
    showNotEligible () {
      this.$store.dispatch('MODAL/ALERT', {
        title: this.$t('card.list.alert.not-eligible.title'),
        textHtml: this.$t('card.list.alert.not-eligible.text',
          ['<a class="text-link underline" href="https://support.paywide.io/hc/en-us/" target="_blank" rel="noopener">' + this.$t('card.list.alert.not-eligible.q-n-a') + '</a>']),
        type: 'error'
      })
    },
    // showActivateFirst () {
    //   this.$store.dispatch('MODAL/ALERT', {
    //     text: this.$t('card.list.alert.activate-first')
    //   })
    // },
    showAddNewCardConfirm (product) {
      this.$store.commit('CARD/SET_CARD_PRODUCT', product)
      this.$store.dispatch('MODAL/OPEN', 'AddCardConfirm')
    },
    async requestCreateOrder (productId) {
      this.$store.dispatch('MODAL/ALERT', {
        text: this.$t('card.list.alert.adding-card'),
        noClose: true
      })
      try {
        const id = await this.$store.dispatch('CARD/CREATE_ORDER', { productId })
        if (id) {
          const path = this.localePath({ name: 'cardDetail', params: { id } })
          this.$router.push(path)
        } else {
          await this.$store.dispatch('CARD/GET_CARDS')
        }
        this.$store.dispatch('MODAL/CLOSE')
      } catch (e) {
        const error = e.response.data.message
        this.$store.dispatch('MODAL/ALERT', {
          title: this.$t('card.list.alert.add-card-error-title'),
          text: error,
          type: 'error'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card-item {
  @apply rounded-2xl lg:rounded-[32px] p-5 lg:p-10 min-h-[226px] lg:min-h-[282px] mb-4 lg:mb-5;
  .active {
    @apply bg-primary text-dark;
  }
  .abnormal {
    @apply bg-[#DD0000] text-white;
  }
  .inactive {
    @apply bg-[#E6E6E6] text-[#999999];
  }
}
.card-list .card-item {
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  &:hover {
    @apply cursor-pointer scale-[1.014] border-primary border-2;
  }
}
.new-card-wrap {
  @apply border border-[#E6E6E6];
  position: relative;
  &::after {
    content:
      url('~@/assets/images/icons/new-card-hover.svg');
    overflow: hidden;
    position: absolute;
    top: 0;
    left: -50000px;
  }

  @apply hover:cursor-pointer;
  &.disabled {
    @apply hover:shadow-none cursor-not-allowed;
  }
  .icon-new-card {
    @apply w-12 h-12;
    background-image: url('~@/assets/images/icons/new-card-normal.svg');
    background-position: center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  &:hover .icon-new-card {
    background-image: url('~@/assets/images/icons/new-card-hover.svg');
  }
  &.disabled .icon-new-card {
    background-image: url('~@/assets/images/icons/new-card-disabled.svg');
  }
}
</style>
