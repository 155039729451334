<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    v-on="$listeners"
    :click-to-close="false"
  >
    <div class="modal__content p-4 md:p-4 text-center">
      <div class="modal__title heading max-w-[438px] mx-auto md:!text-2xl text-dark leading-11 text-left mb-2">
        {{ name }}
      </div>
      <section v-if="features&&features.length" class="notice max-w-[438px] mx-auto text-left font-semibold text-dark">
        <p>✅ The features of this card include:</p>
        <ul class="font-semibold mb-[20px] pl-6 list-disc">
          <li v-for="feature in features" :key="feature">{{ feature }}</li>
        </ul>
      </section>
      <section v-if="scenarios&&scenarios.length" class="notice max-w-[438px] mx-auto text-left font-semibold text-dark">
        <p>✅ The card can be used in the following scenarios:</p>
        <ul class="font-semibold mb-[20px] pl-6 list-disc">
          <li v-for="scenario in scenarios" :key="scenario">{{ scenario }}</li>
          <li>…and many more!</li>
        </ul>
      </section>
      <section v-if="notes&&notes.length" class="notice max-w-[438px] mx-auto text-left font-semibold text-dark">
        <p>💡 <b>Note</b>:</p>
        <ul class="font-semibold mb-[20px] pl-6 list-disc">
          <li v-for="note in notes" :key="note">{{ note }}</li>
        </ul>
      </section>
      <div class="modal__action">
        <button
          type="button"
          class="btn btn-fixed-width btn-light"
          @click="close"
        >{{ $t('button.cancel') }}</button>
        <button
          type="button"
          class="btn btn-fixed-width"
          @click="requestCreateOrder"
        >{{ $t('button.confirm') }}</button>
      </div>
    </div>
    <button class="modal__close" @click="close">
      <icon-close-popup />
    </button>
  </vue-final-modal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal'

export default {
  components: { VueFinalModal },
  name: 'AddCardConfirm',
  inheritAttrs: false,
  data () {
    return {
    }
  },
  computed: {
    productId () {
      return this.$store.getters['CARD/CARD_PRODUCT']?.id
    },
    name () {
      return this.$store.getters['CARD/CARD_PRODUCT']?.name
    },
    features () {
      return this.$store.getters['CARD/CARD_PRODUCT']?.feature
    },
    scenarios () {
      return this.$store.getters['CARD/CARD_PRODUCT']?.scenario
    },
    notes () {
      return this.$store.getters['CARD/CARD_PRODUCT']?.note
    }
  },
  mounted () {},
  methods: {
    async requestCreateOrder () {
      const productId = this.productId
      this.$store.dispatch('MODAL/ALERT', {
        text: this.$t('card.list.alert.adding-card'),
        noClose: true
      })
      try {
        const id = await this.$store.dispatch('CARD/CREATE_ORDER', { productId })
        if (id) {
          const path = this.localePath({ name: 'cardDetail', params: { id } })
          this.$router.push(path)
        } else {
          await this.$store.dispatch('CARD/GET_CARDS')
        }
        this.$store.dispatch('MODAL/CLOSE')
      } catch (e) {
        const error = e.response.data.message
        this.$store.dispatch('MODAL/ALERT', {
          title: this.$t('card.list.alert.add-card-error-title'),
          text: error,
          type: 'error'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles.scss';
button {
  &.disabled {
    @apply cursor-not-allowed;
    // @apply opacity-25;
  }
}
.modal__action {
  @apply flex flex-col-reverse sm:flex-row gap-2;
  @apply mb-2;
}
</style>
