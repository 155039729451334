import request from '@/utils/request'

export function createOrder (currency, productId) {
  return request({
    url: '/order',
    method: 'post',
    data: {
      currency,
      productId
    }
  })
}

export function editOrder (id, currency) {
  return request({
    url: `/order/${id}`,
    method: 'put',
    data: {
      currency
    }
  })
}

export function pay ({ id, currency, hash, nonce }) {
  return request({
    url: `/order/${id}/pay`,
    method: 'post',
    data: {
      currency,
      hash,
      nonce
    }
  })
}

export function getCards () {
  return request({
    url: '/card',
    method: 'get'
  })
}

export function getCard (id) {
  // return new Promise((resolve) => {
  //   resolve({
  //     status: 'new',
  //     hash: '',
  //     number: '4931',
  //     balance: '0',
  //     pendingTopup: '0'
  //   })
  // })
  return request({
    url: `/card/${id}`,
    method: 'get'
  })
}

export function getOrder (id) {
  return request({
    url: `/order/${id}`,
    method: 'get'
  })
}

export function getCardImage ({ id }) {
  return request({
    url: `/card/${id}/img`,
    method: 'get'
  })
}

export function getTopupInfo ({ id, currency }) {
  return request({
    url: `/card/${id}/topup`,
    method: 'get',
    params: {
      currency
    }
  })
}

export function topup ({ id, currency, hash, nonce }) {
  return request({
    url: `/card/${id}/topup/pay`,
    method: 'post',
    data: {
      currency,
      hash,
      nonce
    }
  })
}
export function getNonce () {
  return request({
    url: '/nonce',
    method: 'post'
  })
}

export function queryTransactionHistory ({ id, skip, limit }) {
  return request({
    url: `/card/${id}/transaction`,
    method: 'get',
    params: {
      skip,
      limit
    }
  })
}

export function queryPendingTransactionHistory ({ id, skip, limit }) {
  return request({
    url: `/card/${id}/pending-transaction`,
    method: 'get',
    params: {
      skip,
      limit
    }
  })
}

export function get3DS (id) {
  return request({
    url: `/card/${id}/3ds`,
    method: 'get'
  })
}

export function getState () {
  return request({
    url: '/state',
    method: 'get'
  })
}

export function checkInvitationCode (code) {
  return request({
    url: '/invitation/check',
    method: 'get',
    params: {
      code
    }
  })
}

export function activateUser (code) {
  return request({
    url: '/user/activate',
    method: 'post',
    data: {
      code
    }
  })
}

export function auth ({ payerSig }) {
  return request({
    url: '/auth',
    method: 'post',
    headers: {
      'X-SIGNATURE': payerSig
    }
  })
}

export function cancelTransaction ({ id }) {
  return request({
    url: `/order/${id}/pay/cancel`,
    method: 'post'
  })
}
export function signOut () {
  return request({
    url: '/sign-out',
    method: 'post'
  })
}

export function getUserInvitation () {
  return request({
    url: '/user/invitation',
    method: 'get'
  })
}

export function getSwapInfo (data) {
  return request({
    url: '/swap',
    method: 'post',
    data
  })
}

export function getConfirmations (currency) {
  return request({
    url: `/confirmations/${currency}`,
    method: 'get'
  })
}
