<template>
  <vue-final-modal
    v-slot="{ close }"
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    v-on="$listeners"
    :click-to-close="false"
  >
    <div class="modal__content p-4 md:p-8 text-center">
      <div class="modal__title heading max-w-[438px] mx-auto md:!text-2xl text-dark leading-11 text-left mb-2">
        {{ $t('modal.invitation.code') }}
      </div>
      <div class="max-w-[438px] mx-auto mb-[10px] text-left">
        <p class="font-semibold tracking-[-0.01rem]">{{ $t('modal.invitation.enter-code') }}</p>
        <ul class="font-semibold mb-[50px] pl-6 list-disc">
          <li>{{ $t('modal.invitation.how-to') }} <a
            class="text-link underline whitespace-nowrap"
            href="https://t.me/paywide_io" target="_blank" rel="noopener">{{ $t('modal.invitation.join-us') }}</a>
          </li>
        </ul>
        <div class="input-field max-w-[378px] mx-auto" :class="{ error: codeError }">
          <input class="w-full" v-model="code" :disabled="isRequesting" @input="validateCode">
        </div>
        <div class="text-sm max-w-[378px] mx-auto pt-2 px-4 min-h-[50px]">
          <i18n v-if="codeReachLimit" path="modal.invitation.error-msg.reach-limit" tag="span" class="text-left text-sm">
            <a class="text-link underline whitespace-nowrap" href="https://t.me/paywide_io" target="_blank" rel="noopener">{{ $t('modal.invitation.error-msg.telegram-link') }}</a>
          </i18n>
          <span v-else-if="codeError" class="whitespace-nowrap text-left text-sm text-danger">{{ codeError }}</span>
        </div>
      </div>
      <div class="mb-2">
        <button
          type="button"
          class="btn btn-fixed-width"
          :class="{ disabled: confirmDisabled }"
          @click="handleClick"
        >{{ $t('button.confirm') }}</button>
      </div>
    </div>
    <button class="modal__close" @click="disconnect();close()">
      <icon-close-popup />
    </button>
  </vue-final-modal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal'
import { checkInvitationCode, activateUser } from '@/utils/api/card'

export default {
  components: { VueFinalModal },
  name: 'InvitationInput',
  inheritAttrs: false,
  data () {
    return {
      code: '',
      codeError: '',
      codeReachLimit: false,
      error: '',
      isRequesting: false
    }
  },
  computed: {
    confirmDisabled () {
      return !this.code || this.codeError || this.isRequesting
    }
  },
  mounted () {
    const cachedCode = this.$store.getters['CARD/REFERRAL_CODE']
    if (cachedCode) {
      this.code = cachedCode
      this.handleClick(true)
    }
  },
  methods: {
    validateCode () {
      this.codeError = ''
      this.codeReachLimit = false
      if (this.code.trim().length === 0) {
        // this.codeError = 'Invitation code is required.'
        this.codeError = this.$t('modal.invitation.error-msg.required')
      }
      // 未定義 regex
      // else if (!/{regex}/.test(this.code)) {
      //   this.codeError = 'This invitation code is in the wrong format.'
      // }
    },
    async handleClick (autoSubmit) {
      this.error = ''
      this.codeReachLimit = false
      this.validateCode()
      if (!autoSubmit && this.confirmDisabled) {
        return
      }
      this.isRequesting = true
      try {
        await checkInvitationCode(this.code)
        await activateUser(this.code)
        await this.$store.dispatch('CARD/GET_STATE')
        if (!autoSubmit) {
          this.$store.dispatch('MODAL/OPEN', 'AlertSuccess')
        } else {
          this.$store.dispatch('MODAL/CLOSE')
        }
      } catch (e) {
        this.error = e.response.data.message
        if (this.error.toLowerCase().includes('reach limit')) {
          // this.code = ''
          this.codeError = ''
          this.codeReachLimit = true
        } else if (this.error.toLowerCase().includes('invalid code')) {
          // this.code = ''
          this.codeError = this.$t('modal.invitation.error-msg.invalid')
        } else {
          this.$store.dispatch('MODAL/ALERT', {
            title: this.$t('modal.invitation.alert.title'),
            text: this.$t('modal.invitation.alert.text'),
            type: 'error',
            // 照目前 input 關掉的執行
            closeCallback: this.disconnect
          })
        }
      } finally {
        // remove cached promo code both failed and succeed
        if (this.$store.getters['CARD/REFERRAL_CODE']?.length) {
          this.$store.dispatch('CARD/CACHE_REFERRAL_CODE', '')
        }
        this.isRequesting = false
      }
    },
    disconnect () {
      this.$store.dispatch('CARD/SIGN_OUT')
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles.scss';
.input-field {
  @apply relative;
  @apply block text-left;
  @apply bg-[#E6E6E6];
  @apply border-2 border-[#E6E6E6];
  @apply rounded-xl h-[60px] px-4;
  input {
    @apply bg-transparent;
    @apply focus:outline-none;
    @apply font-heading text-center text-dark text-3xl leading-[38px];
    @apply py-[9px] max-w-full;
  }
  &.error {
    @apply border-2 border-red-400;
  }
}
</style>
