<template>
  <vue-final-modal
    v-bind="$attrs"
    classes="modal-container"
    content-class="modal-content"
    v-on="$listeners"
    :click-to-close="false"
  >
    <div class="modal__content p-4 md:p-8 text-center">
      <simple-picture img-class="w-full mx-auto mt-[22px] mb-[30px]" src="@/assets/images/modals/kv-pending.png" alt="" x="2" />
      <div class="modal__title heading text-4xl leading-11 text-dark mb-10">
        <template v-if="isTopUpPage">{{ $t('modal.waiting-confirm.processing') }}</template>
        <template v-else>{{ $t('modal.waiting-confirm.pending') }}</template>
        <template v-if="confirmations>=0&&completedConfirmations>0">
          <span>(</span>
          <span v-if="confirmations">{{confirmations}}</span>
          <span v-else>0</span>
          <span v-if="completedConfirmations" >/{{completedConfirmations}}</span>
          <span>)</span>
        </template>
      </div>
      <section class="notice max-w-[438px] mx-auto text-left font-semibold">
        <!-- <div>Notice</div> -->
        <ul class="list-disc list-outside pl-6">
          <li>{{ $t('modal.waiting-confirm.notice.0') }}</li>
          <li>{{ $t('modal.waiting-confirm.notice.1') }}</li>
          <li>{{ $t('modal.waiting-confirm.notice.2') }}</li>
        </ul>
      </section>
    </div>
    <!-- <button class="modal__close" @click="close">
      <font-awesome-icon :icon="['fas', 'xmark']" class="text-gray-500" size="2x" />
    </button> -->
  </vue-final-modal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal'
import SimplePicture from '@/components/SimplePicture.vue'
import { mapGetters } from 'vuex'
import { cancelTransaction } from '@/utils/api/card'
import Log from '@/utils/log'
export default {
  components: { VueFinalModal, SimplePicture },
  name: 'WaitingConfirm',
  inheritAttrs: false,
  data () {
    return {
      pollingCardStatus: null,
      polling: null,
      checkedHash: false,
      confirmations: null,
      completedConfirmations: null,
      txCompleted: false
    }
  },
  computed: {
    ...mapGetters('WALLET', [
      'ADDRESS',
      'TX_HASH',
      'TX_CURRENCY'
    ]),
    ...mapGetters('CARD', [
      'CURRENT_ID',
      'TOPUP_API_COMPLETED'
    ]),
    isTopUpPage () {
      return this.$route.name === 'topup'
    }
  },
  watch: {
    TX_CURRENCY (val) {
      this.completedConfirmations = null
      if (this.TX_CURRENCY) {
        this.getCompletedConfirmations(this.TX_CURRENCY)
      }
    }
  },
  created () {
    this.getCompletedConfirmations(this.TX_CURRENCY)
    this.pollCardStatus()
  },
  methods: {
    async getCompletedConfirmations (currency) {
      if (currency && !this.completedConfirmations) {
        const result = await this.$store.dispatch('CARD/GET_CONFIRMATIONS', currency)
        if (result.confirmations) {
          this.completedConfirmations = result.confirmations
        }
      }
    },
    pollCardStatus () {
      if (this.pollingCardStatus) {
        clearInterval(this.pollingCardStatus)
      }
      this.pollingCardStatus = setInterval(async () => {
        const { status, hash: orderHash, currency, cardId } = await this.$store.dispatch('CARD/GET_CARD')
        // const provider = this.PROVIDER
        const hash = this.TX_HASH || orderHash

        if (!this.completedConfirmations) {
          this.getCompletedConfirmations(currency)
        }
        if (hash) {
          if (!this.txCompleted) {
            try {
              const confirmations = await this.$store.dispatch('WALLET/GET_TX_CONFIRMATIONS', { hash })
              this.confirmations = confirmations || 0
              // check completed
              if (this.completedConfirmations && this.confirmations >= this.completedConfirmations) {
                this.txCompleted = true
              }
            } catch (e) {
              console.warn('get transaction confirmations error', e.message)
            }
            if (!this.checkedHash) {
              try {
                Log('fetch tx receipt', hash)
                const txReceipt = await this.$store.dispatch('WALLET/GET_TX_RECEIPT', { hash })
                Log('txReceipt', txReceipt)
                this.checkedHash = true
                if (txReceipt?.status === 'reverted' || txReceipt?.logs.length === 0) {
                  Log('WaitingConfirm tx status reverted')
                  const id = this.CURRENT_ID
                  const params = { id }
                  await cancelTransaction(params)
                  this.$store.dispatch('MODAL/OPEN', 'TopUpFail')
                  // refresh card status
                  this.$store.dispatch('CARD/GET_CARD')
                  return
                }
              } catch (e) {
                console.warn('fetch transaction receipt error', e.message)
              }
            }
          }
        }
        if (['active', 'waiting to get'].includes(status)) {
          if (cardId) {
            this.$store.commit('CARD/SET_CURRENT_ID', {
              id: cardId,
              type: 'card'
            })
          }
          this.$store.dispatch('MODAL/CLOSE')
          this.$store.dispatch('MODAL/OPEN', 'TopUpComplete')
          clearInterval(this.pollingCardStatus)
        }
      }, 5000)
    }
  },
  beforeDestroy () {
    clearInterval(this.pollingCardStatus)
  }
}
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
