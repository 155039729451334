<template>
  <div class="fixed w-full bg-primary z-50">
    <nav
      class="w-full max-h-20 container px-4 py-4 md:flex md:justify-between md:items-center"
    >
      <div class="brand max-h-12 flex items-center justify-between">
        <router-link
          :to="localePath('/')"
          class="flex items-center text-xl font-bold text-gray-800 md:text-base hover:text-blue-400"
        >
          <simple-picture
            img-class="max-h-12 md:max-h-20"
            src="@/assets/images/logo.png"
            alt="PayWide"
            x="2"
          />
          <!-- <span v-if="isDev" class="text-xs md:text-base text-white hover:text-white font-semibold">Beta</span> -->
        </router-link>

        <!-- Mobile network button -->
        <div
          v-if="IS_CONNECTED"
          class="relative block md:hidden"
          v-click-outside="handleMobileNetworkClickOutside"
        >
          <button class="wallet-info" @click="handleMobileClickNetworkMenu">
            <img
              v-if="checkChainIdValid(CHAIN_ID) === 'ethereum'"
              class="max-w-[1rem]"
              :src="chainIcon[`${checkChainIdValid(CHAIN_ID)}.png`]"
              alt=""
            />
            <img
              v-else-if="checkChainIdValid(CHAIN_ID) === 'bsc'"
              class="max-w-[1rem]"
              :src="chainIcon[`${checkChainIdValid(CHAIN_ID)}.png`]"
              alt=""
            />
            <img
              v-else-if="checkChainIdValid(CHAIN_ID) === 'avalanche'"
              class="max-w-[1rem]"
              :src="chainIcon[`${checkChainIdValid(CHAIN_ID)}.png`]"
              alt=""
            />
            <img
              v-else-if="checkChainIdValid(CHAIN_ID) === 'polygon'"
              class="max-w-[1rem]"
              :src="chainIcon[`${checkChainIdValid(CHAIN_ID)}.png`]"
              alt=""
            />
            <img
              v-else-if="checkChainIdValid(CHAIN_ID) === 'arbitrum'"
              class="max-w-[1rem]"
              :src="chainIcon[`${checkChainIdValid(CHAIN_ID)}.png`]"
              alt=""
            />
            <img
              v-else-if="checkChainIdValid(CHAIN_ID) === 'optimism'"
              class="max-w-[1rem]"
              :src="chainIcon[`${checkChainIdValid(CHAIN_ID)}.png`]"
              alt=""
            />
            <img
              v-else-if="!checkChainIdValid(CHAIN_ID)"
              class="max-w-[1rem]"
              :src="banPNG"
              alt=""
            />
            <font-awesome-icon
              v-if="ALLOW_ACTIONS.network && chainArray.length > 1"
              :icon="['fas', 'chevron-down']"
            />
          </button>
          <div v-if="ALLOW_ACTIONS.network" class="dropdown-menu-wrap right-0">
            <transition name="dropdown">
              <dropdown v-if="networkMenuMobile">
                <dropdown-item
                  v-if="!checkChainIdValid(CHAIN_ID)"
                  @click.prevent
                >
                  <!-- Please switch to <br />the network <br />which we support. -->
                  <span
                    v-html="$t('navbar.network-warning')"
                    class="text-sm text-danger"
                  >
                  </span>
                </dropdown-item>
                <dropdown-item
                  v-for="(chain, i) in chainArray"
                  :key="i"
                  @click="handleNetworkItemClick(chain.id)"
                >
                  <img
                    v-if="['1', '3', '5'].includes(chain.id.toString())"
                    class="max-w-[1rem]"
                    :src="chainIcon['ethereum.png']"
                    alt=""
                  />
                  <img
                    v-if="['56', '97'].includes(chain.id.toString())"
                    class="max-w-[1rem]"
                    :src="chainIcon['bsc.png']"
                    alt=""
                  />
                  <img
                    v-if="['137'].includes(chain.id.toString())"
                    class="max-w-[1rem]"
                    :src="chainIcon['polygon.png']"
                    alt=""
                  />
                  <img
                    v-if="['43113', '43114'].includes(chain.id.toString())"
                    class="max-w-[1rem]"
                    :src="chainIcon['avalanche.png']"
                    alt=""
                  />
                  <span>{{ chain.name }}</span>
                </dropdown-item>
              </dropdown>
            </transition>
          </div>
        </div>
        <!-- Mobile menu button -->
        <div @click="showMenu = !showMenu" class="flex md:hidden">
          <button type="button" class="text-gray-600 focus:outline-none">
            <div class="menu" :class="{ open: showMenu }">
              <div class="close"></div>
            </div>
          </button>
        </div>
      </div>

      <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
      <ul
        :class="showMenu ? 'flex' : 'hidden'"
        class="whitespace-nowrap absolute md:relative left-0 md:left-auto w-full md:w-auto bg-primary flex-col space-y-4 px-4 py-8 md:m-0 md:p-0 md:flex md:space-y-0 md:flex-row md:items-center md:space-x-10 md:mt-0"
      >
        <li class="text-sm font-semibold cursor-pointer">
          <a
            href="https://support.paywide.io/hc/en-us/"
            target="_blank"
            rel="noopener"
            >{{ $t("navbar.q-n-a") }}</a
          >
        </li>
        <li v-if="USER_ACTIVE" class="text-sm font-semibold cursor-pointer">
          <router-link
            id="navbar-card-list-link"
            :to="localePath({ name: 'cardList' })">{{
            $t("navbar.my-card")
          }}</router-link>
        </li>
        <li>
          <div
            class="relative hidden md:block max-h-[38px]"
            v-click-outside="handleLocaleClickOutside"
          >
            <button id="navbar-locale-menu-btn" @click="handleClickLocaleMenu">
              <img
                class="max-h-[38px]"
                src="@/assets/images/icons/globe.svg"
                alt=""
              />
            </button>
            <div class="dropdown-menu-wrap">
              <transition name="dropdown">
                <dropdown v-if="localeMenu">
                  <dropdown-item
                    v-for="locale in locales"
                    :key="locale"
                    @click="handleLocaleItemClick(locale)"
                  >
                    <img
                      class="max-w-[1rem]"
                      :src="localeSVG[`${locale}.png`]"
                      alt=""
                    />
                    <span>{{ $t(locale) }}</span>
                  </dropdown-item>
                </dropdown>
              </transition>
            </div>
          </div>
          <div class="relative block md:hidden max-h-[38px]">
            <button id="navbar-locale-menu-btn-mobile" @click="handleMobileClickLocaleMenu">
              <div
                class="flex justify-center items-center gap-3 text-dark text-sm font-semibold"
              >
                <span class="block md:hidden max-h-[38px]">{{
                  $t("navbar.language")
                }}</span>
                <font-awesome-icon
                  class="text-dark"
                  :icon="['fas', 'chevron-down']"
                  :flip="localeMenuMobile ? 'vertical' : null"
                />
              </div>
              <!-- <font-awesome-icon v-else :icon="['fas', 'chevron-down']" /> -->
            </button>
          </div>
        </li>
        <template v-if="localeMenuMobile">
          <li
            class="text-sm font-semibold text-gray-500 cursor-pointer"
            v-for="locale in locales"
            :key="locale"
            @click="handleLocaleItemClick(locale)"
          >
            <span>{{ $t(locale) }}</span>
          </li>
        </template>
        <li class="md:!ml-8 text-sm font-semibold text-dark">
          <div v-if="IS_CONNECTED" class="flex space-x-2 justify-center">
            <div
              class="relative hidden md:block"
              v-click-outside="handleNetworkClickOutside"
            >
              <button
                v-if="checkChainIdValid(CHAIN_ID)"
                id="navbar-network-menu-button"
                class="wallet-info"
                @click="handleClickNetworkMenu"
              >
                <img
                  v-if="checkChainIdValid(CHAIN_ID) === 'ethereum'"
                  class="max-w-[1rem]"
                  :src="chainIcon[`${checkChainIdValid(CHAIN_ID)}.png`]"
                  alt=""
                />
                <img
                  v-else-if="checkChainIdValid(CHAIN_ID) === 'bsc'"
                  class="max-w-[1rem]"
                  :src="chainIcon[`${checkChainIdValid(CHAIN_ID)}.png`]"
                  alt=""
                />
                <img
                  v-else-if="checkChainIdValid(CHAIN_ID) === 'avalanche'"
                  class="max-w-[1rem]"
                  :src="chainIcon[`${checkChainIdValid(CHAIN_ID)}.png`]"
                  alt=""
                />
                <img
                  v-else-if="checkChainIdValid(CHAIN_ID) === 'polygon'"
                  class="max-w-[1rem]"
                  :src="chainIcon[`${checkChainIdValid(CHAIN_ID)}.png`]"
                  alt=""
                />
                <img
                  v-else-if="checkChainIdValid(CHAIN_ID) === 'arbitrum'"
                  class="max-w-[1rem]"
                  :src="chainIcon[`${checkChainIdValid(CHAIN_ID)}.png`]"
                  alt=""
                />
                <img
                  v-else-if="checkChainIdValid(CHAIN_ID) === 'optimism'"
                  class="max-w-[1rem]"
                  :src="chainIcon[`${checkChainIdValid(CHAIN_ID)}.png`]"
                  alt=""
                />
                <img
                  v-else-if="!checkChainIdValid(CHAIN_ID)"
                  class="max-w-[1rem]"
                  :src="banPNG"
                  alt=""
                />
                <span class="capitalize">{{ NETWORK&&NETWORK.name }}</span>
                <font-awesome-icon
                  v-if="ALLOW_ACTIONS.network && chainArray.length > 1"
                  :icon="['fas', 'chevron-down']"
                />
              </button>
              <el-tooltip
                v-else
                class="item"
                effect="dark"
                content="Please switch to the network which we support."
                placement="right"
              >
                <button class="wallet-info" @click="handleClickNetworkMenu">
                  <img
                    v-if="checkChainIdValid(CHAIN_ID) === 'ethereum'"
                    class="max-w-[1rem]"
                    :src="
                      chainIcon[`${checkChainIdValid(CHAIN_ID)}.png`]
                    "
                    alt=""
                  />
                  <img
                    v-else-if="checkChainIdValid(CHAIN_ID) === 'bsc'"
                    class="max-w-[1rem]"
                    :src="
                      chainIcon[`${checkChainIdValid(CHAIN_ID)}.png`]
                    "
                    alt=""
                  />
                  <img
                    v-else-if="
                      checkChainIdValid(CHAIN_ID) === 'avalanche'
                    "
                    class="max-w-[1rem]"
                    :src="
                      chainIcon[`${checkChainIdValid(CHAIN_ID)}.png`]
                    "
                    alt=""
                  />
                  <img
                    v-else-if="checkChainIdValid(CHAIN_ID) === 'polygon'"
                    class="max-w-[1rem]"
                    :src="
                      chainIcon[`${checkChainIdValid(CHAIN_ID)}.png`]
                    "
                    alt=""
                  />
                  <img
                    v-else-if="checkChainIdValid(CHAIN_ID) === 'arbitrum'"
                    class="max-w-[1rem]"
                    :src="
                      chainIcon[`${checkChainIdValid(CHAIN_ID)}.png`]
                    "
                    alt=""
                  />
                  <img
                    v-else-if="checkChainIdValid(CHAIN_ID) === 'optimism'"
                    class="max-w-[1rem]"
                    :src="
                      chainIcon[`${checkChainIdValid(CHAIN_ID)}.png`]
                    "
                    alt=""
                  />
                  <img
                    v-else-if="!checkChainIdValid(CHAIN_ID)"
                    class="max-w-[1rem]"
                    :src="banPNG"
                    alt=""
                  />
                  <span class="capitalize">{{ NETWORK&&NETWORK.name }}</span>
                  <font-awesome-icon
                    v-if="ALLOW_ACTIONS.network"
                    :icon="['fas', 'chevron-down']"
                  />
                </button>
              </el-tooltip>
              <div v-if="ALLOW_ACTIONS.network" class="dropdown-menu-wrap">
                <transition name="dropdown">
                  <dropdown v-if="networkMenu">
                    <dropdown-item
                      v-for="(chain, i) in chainArray"
                      :key="i"
                      @click="handleNetworkItemClick(chain.id)"
                    >
                      <img
                        v-if="['1', '3', '5'].includes(chain.id.toString())"
                        class="max-w-[1rem]"
                        :src="chainIcon['ethereum.png']"
                        alt=""
                      />
                      <img
                        v-if="['56', '97'].includes(chain.id.toString())"
                        class="max-w-[1rem]"
                        :src="chainIcon['bsc.png']"
                        alt=""
                      />
                      <img
                        v-if="['137'].includes(chain.id.toString())"
                        class="max-w-[1rem]"
                        :src="chainIcon['polygon.png']"
                        alt=""
                      />
                      <img
                        v-if="['43113', '43114'].includes(chain.id.toString())"
                        class="max-w-[1rem]"
                        :src="chainIcon['avalanche.png']"
                        alt=""
                      />
                      <img
                        v-if="['42161'].includes(chain.id.toString())"
                        class="max-w-[1rem]"
                        :src="chainIcon['arbitrum.png']"
                        alt=""
                      />
                      <img
                        v-if="['10'].includes(chain.id.toString())"
                        class="max-w-[1rem]"
                        :src="chainIcon['optimism.png']"
                        alt=""
                      />
                      <span>{{ chain.name }}</span>
                    </dropdown-item>
                  </dropdown>
                </transition>
              </div>
            </div>
            <div class="relative" v-click-outside="handleAccountClickOutside">
              <button
                id="navbar-account-menu-btn"
                class="wallet-info relative"
                @click="handleClickAccountMenu"
              >
                <div class="overflow-hidden">{{ displayedAddress }}</div>
                <jazz-icon class="h-[20px]" :address="ADDRESS" :diameter="20" />
              </button>
              <div class="dropdown-menu-wrap dropdown-menu-account">
                <transition name="dropdown">
                  <dropdown v-if="accountMenu">
                    <dropdown-item
                      v-if="ALLOW_ACTIONS.account"
                      @click="handleChangeAccount"
                    >
                      <img class="max-w-[2rem]" :src="changePNG" alt="" />
                      <span>{{ $t("navbar.change-wallet") }}</span>
                    </dropdown-item>
                    <dropdown-item @click="handleDisconnect">
                      <img class="max-w-[2rem]" :src="signOutPNG" alt="" />
                      <span>{{ $t("navbar.logout") }}</span>
                    </dropdown-item>
                  </dropdown>
                </transition>
              </div>
            </div>
          </div>
          <button
            v-else
            id="navbar-wallet-btn"
            class="btn btn-dark btn-wallet"
            @click="handleConnectWalletClick"
          >
            {{ $t("button.wallet") }}
          </button>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>
import SimplePicture from '@/components/SimplePicture.vue'
import { mapGetters } from 'vuex'
import ClickOutside from 'vue-click-outside'
import signOutPNG from '@/assets/images/signout.png'
import changePNG from '@/assets/images/change.png'
import { map, compact } from 'lodash-es'
import MobileDetect from 'mobile-detect'
import chainIcon from '@/assets/images/chain'
import banPNG from '@/assets/images/chain/ban.png'
// import LocaleSwitcher from '@/components/LocaleSwitcher.vue'
import localeSVG from '@/assets/images/locale-icons'
const supportedChain = JSON.parse(process.env.VUE_APP_SUPPORTED_CHAIN)

export default {
  name: 'NavBar',
  components: {
    SimplePicture
    // LocaleSwitcher
  },
  data () {
    return {
      banPNG,
      chainIcon,
      showMenu: false,
      accountMenu: false,
      signOutPNG,
      changePNG,
      networkMenu: false,
      networkMenuMobile: false,
      localeMenu: false,
      localeMenuMobile: false,
      supportedChain,
      mobile: null,
      localeSVG,
      locales: ['en', 'zh-tw', 'zh-cn', 'tr', 'ja', 'de']
    }
  },
  computed: {
    ...mapGetters('WALLET', [
      'ADDRESS',
      'IS_CONNECTED',
      'IS_LOGIN',
      'NETWORK',
      'CHAIN_ID',
      'ALLOW_ACTIONS'
    ]),
    ...mapGetters('CARD', ['HAS_CARDS', 'USER_ACTIVE']),
    chainArray () {
      const filterArray = map(supportedChain, (value, key) => {
        if (key !== (this.CHAIN_ID && this.CHAIN_ID.toString())) {
          return { id: key, name: value }
        }
      })
      return compact(filterArray)
    },
    displayedAddress () {
      return this.ADDRESS.replace(/(.{5}).+(.{4})/, (match, p1, p2) => {
        return `${p1}...${p2}`
      })
    }
  },
  mounted () {
    const md = new MobileDetect(window.navigator.userAgent)
    this.mobile = md.mobile()
  },
  methods: {
    checkChainIdValid (chainId) {
      if (!chainId) return false
      const chainIdString = chainId.toString()
      const supportedChain = Object.keys(this.supportedChain).map((key) =>
        key.toString()
      )
      const ethereumChian = ['1', '11155111', '17000']
      const bscChian = ['56', '97']
      const avalancheChain = ['43114', '43113']
      const polygonChain = ['137', '80001']
      const arbitrumChains = ['42161']
      const optimismChains = ['10']
      if (
        ethereumChian.includes(chainIdString) &&
        supportedChain.includes(chainIdString)
      ) {
        return 'ethereum'
      }
      if (
        bscChian.includes(chainIdString) &&
        supportedChain.includes(chainIdString)
      ) {
        return 'bsc'
      }
      if (
        avalancheChain.includes(chainIdString) &&
        supportedChain.includes(chainIdString)
      ) {
        return 'avalanche'
      }
      if (
        polygonChain.includes(chainIdString) &&
        supportedChain.includes(chainIdString)
      ) {
        return 'polygon'
      }
      if (
        arbitrumChains.includes(chainIdString) &&
        supportedChain.includes(chainIdString)
      ) {
        return 'arbitrum'
      }
      if (
        optimismChains.includes(chainIdString) &&
        supportedChain.includes(chainIdString)
      ) {
        return 'optimism'
      }
      return false
    },
    async handleConnectWalletClick () {
      this.$store.dispatch('WALLET/OPEN_WEB3MODAL')
    },
    openLocaleMenu () {
      this.localeMenu = true
    },
    closeLocaleMenu () {
      this.localeMenu = false
    },
    toggleLocaleMenu () {
      this.localeMenu = !this.localeMenu
    },
    toggleMobileLocaleMenu () {
      this.localeMenuMobile = !this.localeMenuMobile
    },
    handleClickLocaleMenu () {
      this.toggleLocaleMenu()
    },
    handleMobileClickLocaleMenu () {
      this.toggleMobileLocaleMenu()
    },
    openAccountMenu () {
      this.accountMenu = true
    },
    closeAccountMenu () {
      this.accountMenu = false
    },
    toggleAccountMenu () {
      this.accountMenu = !this.accountMenu
    },
    handleClickAccountMenu () {
      this.toggleAccountMenu()
    },
    openNetworkMenu () {
      this.networkMenu = true
    },
    closeNetworkMenu () {
      this.networkMenu = false
    },
    toggleNetworkMenu () {
      this.networkMenu = !this.networkMenu
    },
    toggleMobileNetworkMenu () {
      this.networkMenuMobile = !this.networkMenuMobile
    },
    handleClickNetworkMenu () {
      // this.toggleNetworkMenu()
      this.$store.dispatch('WALLET/OPEN_WEB3MODAL')
    },
    handleMobileClickNetworkMenu () {
      // this.toggleMobileNetworkMenu()
      this.$store.dispatch('WALLET/OPEN_WEB3MODAL')
    },
    handleDisconnect () {
      this.$store.dispatch('CARD/SIGN_OUT')
      this.accountMenu = false
    },
    handleChangeAccount () {
      this.$store.dispatch('WALLET/REQUEST_PERMISSIONS')
      this.accountMenu = false
    },
    handleNetworkItemClick (chainId) {
      this.$store.dispatch('WALLET/SWITCH_NETWORK', chainId)
      this.networkMenu = false
    },
    handleLocaleItemClick (locale) {
      this.$store.dispatch('setLang', locale)
      this.localeMenu = false
    },
    handleLocaleClickOutside () {
      this.localeMenu = false
    },
    handleAccountClickOutside () {
      this.accountMenu = false
    },
    handleNetworkClickOutside () {
      this.networkMenu = false
    },
    handleMobileNetworkClickOutside () {
      this.networkMenuMobile = false
    },
    handleMobileLocaleClickOutside () {
      this.localeMenuMobile = false
    }
  },
  directives: {
    ClickOutside
  }
}
</script>

<style lang="scss" scoped>
.title-test {
  @apply text-lg text-blue-500;
}
.menu {
  @apply relative;
  @apply flex justify-center items-center;
  @apply w-6 h-6;

  .close,
  .close::before,
  .close::after {
    content: "";
    @apply bg-gray-500 block absolute;
    @apply h-[2px] w-[20px];
    transition: background ease 0.3s, top ease 0.3s 0.3s, transform ease 0.3s;
  }
  .close::before {
    @apply -top-[6px];
  }
  .close::after {
    @apply top-[6px];
  }

  &.open {
    .close {
      @apply bg-transparent;
    }
    .close::before {
      @apply top-0 rotate-45;
      transition: top ease 0.3s, transform ease 0.3s 0.3s;
    }
    .close::after {
      @apply top-0 -rotate-45;
      transition: top ease 0.3s, transform ease 0.3s 0.3s;
    }
  }
}
.btn.btn-wallet {
  @apply font-semibold;
  @apply py-[10px] px-[30px] rounded-lg;
}
.wallet-info {
  @apply flex justify-center items-center space-x-3;
  @apply px-3 py-2 leading-5;
  @apply bg-[#D1B37A];
  @apply border-[1px] border-[#B99B64];
  @apply rounded-lg;
  @apply font-semibold;
}
// .account-menu {
//   @apply absolute;
//   @apply rounded-b-lg;
//   width: 100px;
//   height: 100px;
//   transform-origin: center top;
//   top: 100%;
//   background: #fff;
//   // transition: all 1s ease-in-out;
// }
.dropdown-menu-wrap {
  @apply absolute;
  @apply top-full;
  transform-origin: center top;
  .dropdown-item {
    @apply justify-start;
  }
  &.dropdown-menu-account {
    @apply right-0;
    @apply justify-center;
    span {
      // for balance to icon img padding left
      @apply pr-2;
    }
  }
}
</style>
