import { createWeb3Modal } from '@web3modal/wagmi'
import { walletConnect, injected } from '@wagmi/connectors'
import {
  createConfig,
  watchAccount,
  getWalletClient,
  disconnect,
  // reconnect,
  switchChain,
  http,
  fallback,
  getBalance,
  readContract,
  readContracts,
  simulateContract,
  writeContract,
  getTransaction,
  waitForTransactionReceipt,
  getTransactionConfirmations,
  sendTransaction
} from '@wagmi/core'
import {
  mainnet, bsc, avalanche, polygon, arbitrum, optimism,
  sepolia, holesky, bscTestnet, avalancheFuji, arbitrumSepolia, optimismSepolia
} from 'viem/chains'
import {
  createClient,
  formatUnits,
  parseUnits,
  erc20Abi,
  parseAbi
} from 'viem'
import {
  ABI,
  TOKENS
} from '@/constants'
import Big from 'big.js'
import { Loading } from 'element-ui'
import router from '@/router'
import Log from '@/utils/log'
// import networks from './network.config'
import { addLocaleToPath } from '@/utils/mixins'
const supportedChain = JSON.parse(process.env.VUE_APP_SUPPORTED_CHAIN)
const supportedCurrency = JSON.parse(process.env.VUE_APP_SUPPORTED_CURRENCY)
const projectId = process.env.VUE_APP_WALLETCONNECT_PROJECT_ID
const RPC_URLS = JSON.parse(process.env.VUE_APP_RPC_URLS || '{}')
const chainDict = {
  1: mainnet,
  11155111: sepolia,
  17000: holesky,
  56: bsc,
  97: bscTestnet,
  43114: avalanche,
  43113: avalancheFuji,
  137: polygon,
  42161: arbitrum,
  421614: arbitrumSepolia,
  10: optimism,
  11155420: optimismSepolia
}
const sortId = {
  // ETH
  1: 1,
  17000: 1,
  11155111: 1,
  // Polygon
  137: 2,
  80001: 2,
  // Arbitrum
  42161: 3,
  421614: 3,
  // Optimism
  10: 4,
  11155420: 4,
  // BSC
  56: 5,
  97: 5,
  // Avalanche
  43114: 6,
  43113: 6
}
Log(supportedChain)
const chains = Object.keys(supportedChain).map(el => chainDict[el])
const chainCmp = (a, b) => {
  const aSid = sortId[a.id] || 9999
  const bSid = sortId[b.id] || 9999
  if (aSid === bSid) {
    return a.id - b.id
  }
  return aSid - bSid
}
chains.sort(chainCmp)
Log(chains)

Big.PE = 30
Big.NE = -19

const wagmiConfig = createConfig({
  logger: {
    warn: (message) => {
      Log(message)
    }
  },
  chains,
  connectors: [
    walletConnect({ projectId, showQrModal: false }),
    // injected({ target: 'metaMask', shimDisconnect: true })
    injected()
  ],
  client ({ chain }) {
    const rpcUrls = RPC_URLS[chain.id]
      ? Array.isArray(RPC_URLS[chain.id])
        ? RPC_URLS[chain.id]
        : [RPC_URLS[chain.id]]
      : null
    if (rpcUrls?.length > 0) {
      const fallbackList = [http()]
      for (const url of rpcUrls) {
        fallbackList.push(http(url))
      }
      return createClient({
        chain,
        transport: fallback(fallbackList)
      })
    }
    return createClient({ chain, transport: http() })
  }
})

const getTokenAddress = (tokenSymbol, chainId) => {
  const token = TOKENS[chainId].find(el => el.symbol.toLowerCase() === tokenSymbol.toLowerCase())
  return token.address
}

const getTokenInfo = (tokenSymbol, chainId) => {
  const token = TOKENS[chainId].find(el => el.symbol.toLowerCase() === tokenSymbol.toLowerCase())
  return token
}

// =========================
// for uniswap
// 取得 ERC20 總供應量
const getErc20TotalSupply = async (contracAddress) => {
  const totalSupply = await readContract(wagmiConfig, {
    abi: erc20Abi,
    address: contracAddress,
    functionName: 'totalSupply'
  })
  return totalSupply
}

// 取得 ERC20 允許 spender 使用金額額度
const getErc20Allowance = async ({ contracAddress, owner, spender }) => {
  const allowance = await readContract(wagmiConfig, {
    abi: erc20Abi,
    address: contracAddress,
    functionName: 'allowance',
    args: [owner, spender]
  })
  return allowance
}

// 允許 spender 使用 ERC20 Token
const approveErc20Token = async ({ contracAddress, spender }) => {
  const totalSupply = await getErc20TotalSupply(contracAddress)
  const { request } = await simulateContract(wagmiConfig, {
    abi: erc20Abi,
    address: contracAddress,
    functionName: 'approve',
    args: [
      spender,
      totalSupply
    ]
  })
  const hash = await writeContract(wagmiConfig, request)
  Log('approveErc20Token', hash)
  return hash
}
const approveErc20TokenAndWait = async ({ contracAddress, spender }) => {
  const hash = await approveErc20Token({ contracAddress, spender })
  const receipt = await waitForTransactionReceipt(wagmiConfig, {
    hash,
    confirmations: 1
  })
  Log('approveErc20TokenAndWait', receipt)
}
// ==========================

const allowActions = {
  metamask: {
    account: true,
    network: true
  },
  walletconnect: {
    account: false,
    network: false
  }
}
const state = {
  config: wagmiConfig,
  allowActions,
  walletName: '',
  isLogin: false,
  isConnected: false,
  isLoading: false,
  address: '',
  chainId: null,
  web3modal: null,
  provider: null,
  Loading: Loading,
  account_changing: false,
  network: {
    name: '',
    icon: 'ethereum',
    valid: false,
    id: null,
    currency: ''
  },
  tempTargetChain: {
    id: null,
    name: null
  },
  instance: null,
  isResettingApp: false,
  swap: null,
  txCurrency: null,
  txHash: null
}
const mutations = {
  SET_PROVIDER (state, payload) {
    state.provider = payload
  },
  SET_ACCOUNT_CHANGING (state, payload) {
    state.account_changing = payload
  },
  SET_ACCOUNT_STATUS (state, payload) {
    state.isLogin = payload
  },
  SET_WALLET_STATUS (state, payload) {
    state.isConnected = payload
  },
  SET_ACCOUNT_INFO (state, account) {
    state.address = account.address
    state.connector = account.connector
    state.isConnected = account.isConnected
    state.isConnecting = account.isConnecting
    state.isDisconnected = account.isDisconnected
    state.isReconnecting = account.isReconnecting
    state.walletStatus = account.status
    state.walletName = account.connector?.name.toLowerCase()
  },
  SET_NETWORK_INFO (state, account) {
    Log('SET_NETWORK_INFO', account.chain)
    state.network = account.chain
    // chain important info example, other info can see in console log from watch event:
    // id: 5
    // network: "goerli"
    // name: "Goerli"
    // nativeCurrency: {name: 'Goerli Ether', symbol: 'ETH', decimals: 18}
    // unsupported: false
    if (state.network) {
      state.network.currency = supportedCurrency[account.chain?.id]
    }
  },
  SET_TARGET_CHAIN (state, payload) {
    state.tempTargetChain = payload
  },
  SET_WEB3MODAL (state, web3modal) {
    state.web3modal = web3modal
  },
  SET_ACTIVE (state, active) {
    state.active = active
  },
  SET_WALLET_NAME (state, name) {
    state.walletName = name
  },
  SET_WEB3MODAL_INSTANCE (state, instance) {
    state.instance = instance
  },
  SET_IS_RESETTING_APP (state, called) {
    state.isResettingApp = called
  },
  SET_SWAP (state, swap) {
    Log('SET_SWAP', swap)
    state.swap = swap
  },
  SET_TX_CURRENCY (state, txCurrency) {
    Log('SET_TX_CURRENCY', txCurrency)
    state.txCurrency = txCurrency
  },
  SET_TX_HASH (state, txHash) {
    Log('SET_TX_HASH', txHash)
    state.txHash = txHash
  }
}

const delay = function (ms) {
  return new Promise(function (resolve) {
    setTimeout(resolve, ms)
  })
}
const actions = {
  async INIT_WEB3MODAL ({ commit, dispatch, state }) {
    Log('INIT_WEB3MODAL')

    // reconnect(wagmiConfig) // auto connect
    const web3modal = createWeb3Modal({ wagmiConfig, projectId, chains })
    commit('SET_WEB3MODAL', web3modal)
    Log('web3modal')
    Log(web3modal)
    this.unsubscribeMethod = web3modal.subscribeState((newState) => {
      Log('subscribeState')
      Log(newState)
    })
    this.unwatchAccountMethod = watchAccount(wagmiConfig, {
      async onChange (account) {
        Log('watchAccount')
        Log(account)

        // set network
        commit('SET_NETWORK_INFO', account)

        commit('SET_ACCOUNT_STATUS', false)
        if (account.address) {
          if (account.address !== state.address) {
            await router.replace(addLocaleToPath(router.currentRoute.params.lang, '/')).catch(() => { })
          }
          // for account.address !== state.address, SET_ACCOUNT_INFO must execute after compare address
          commit('SET_ACCOUNT_INFO', account)
          Log('CARD/GET_STATE_ACTIONS in ACCOUNT_CHANGED_HANDLER')
          dispatch('CARD/GET_STATE_ACTIONS', null, { root: true })
        } else {
          commit('SET_ACCOUNT_INFO', account)
          await dispatch('RESET_APP')
        }
      }
    })
  },
  async OPEN_WEB3MODAL ({ state }) {
    Log('OPEN_WEB3MODAL')
    state.web3modal?.open({ view: 'Networks' })
  },
  async SWITCH_NETWORK ({ commit, state, dispatch }, chainId) {
    try {
      await switchChain(wagmiConfig, {
        chainId
      })
    } catch (e) {
      Log('SWITCH_NETWORK', e.stack)
    }
  },
  async REQUEST_PERMISSIONS ({ commit, state, dispatch }) {
    const walletClient = await getWalletClient(wagmiConfig)
    const permissions = await walletClient.requestPermissions({ eth_accounts: {} })
    Log('permissions', permissions)
  },
  async RESET_APP ({ state, commit, dispatch }) {
    Log('WALLET/RESET_APP')
    // disconnect might double invoke RESET_APP
    if (state.isResettingApp) {
      Log('is Resetting app')
      return
    }
    commit('SET_IS_RESETTING_APP', true)

    dispatch('MODAL/CLOSE', null, { root: true })
    try {
      await disconnect(wagmiConfig)
    } catch (error) {
      console.error(error)
    }
    commit('SET_ACCOUNT_STATUS', false)
    commit('CARD/SET_CARD_STATUS', {}, { root: true })

    commit('SET_SWAP', null)
    commit('SET_TX_CURRENCY', null)
    commit('SET_TX_HASH', null)
    router.replace(addLocaleToPath(router.currentRoute.params.lang, '/')).catch(() => { })
    commit('SET_IS_RESETTING_APP', false)
  },
  async DO_TRADE_TOKEN ({ commit, state, dispatch, rootGetters },
    { amount, slippage, payee, currency, fromTokenSymbol, toTokenSymbol }) {
    try {
      const chainId = parseInt(state.network.id)
      const swap = state.swap // swap info from api

      if (!swap) {
        throw Error('swap info is null')
      }

      // check chain id
      if (swap.id !== chainId) {
        const message = 'chainId does not match'
        Log(message, swap.id, chainId)
        throw Error(message)
      }

      // check slippage
      if (slippage && slippage !== swap.slippage.toString()) {
        const message = 'slippage does not match'
        Log(message, swap.slippage, slippage)
        throw Error(message)
      }
      // check from
      if (swap.fromToken.toLowerCase() !== fromTokenSymbol.toLowerCase()) {
        const message = 'fromToken does not match'
        Log(message, swap.fromToken.toLowerCase(), fromTokenSymbol.toLowerCase())
        throw Error(message)
      }
      const fromTokenInfo = getTokenInfo(fromTokenSymbol, chainId)
      const fromAmount = Big(amount).times(Big(10).pow(fromTokenInfo.decimals))
      if (!fromAmount.eq(swap.fromAmount)) {
        const message = 'amount does not match'
        Log(message, fromAmount.toString(), swap.fromAmount.toString())
        throw Error(message)
      }

      // check to
      if (swap.toToken.toLowerCase() !== toTokenSymbol.toLowerCase()) {
        const message = 'toToken does not match'
        Log(message, swap.toToken.toLowerCase(), toTokenSymbol.toLowerCase())
        throw Error(message)
      }

      // check topup address
      if (swap.address !== payee) {
        throw Error('address does not match')
      }
      // check topup currency
      if (swap.currency !== currency) {
        throw Error('currency does not match')
      }

      if (!fromTokenInfo.isNative) {
        const fromTokenAddress = getTokenAddress(fromTokenSymbol, chainId)
        const spender = swap.transactionRequestData.to
        Log('spender', spender)

        const allowance = await getErc20Allowance({
          contracAddress: fromTokenAddress,
          owner: state.address,
          spender
        })
        Log('allowance', allowance)
        Log('fromAmount', swap.fromAmount)
        if (Big(allowance).lt(swap.fromAmount)) {
          await approveErc20TokenAndWait({
            contracAddress: fromTokenAddress,
            spender
          })
        }
      }

      // swap
      const hash = await sendTransaction(wagmiConfig, {
        chainId,
        ...swap.transactionRequestData
      })
      for (let i = 0; i < 5; ++i) {
        try {
          await delay(500)
          const tx = await getTransaction(wagmiConfig, { chainId, hash })
          return tx
        } catch (e) {
          console.warn(`getTransaction (swap) failed: ${hash}`)
          console.warn(e.message)
        }
      }
      return {
        hash
      }
    } catch (e) {
      console.warn('wallet (swap): ', e)
      throw Error(e)
    }
  },
  async DO_TRADE ({ commit, state, dispatch, rootGetters }, { amount, payee, toTokenSymbol }) {
    try {
      toTokenSymbol = toTokenSymbol.split('-')[0] // 'usdt-avaxc-erc20', 'usdt-matic-erc20'
      const chainId = parseInt(state.network.id)
      const toTokenAddress = getTokenAddress(toTokenSymbol, chainId)
      const decimals = await readContract(wagmiConfig, {
        abi: erc20Abi,
        address: toTokenAddress,
        functionName: 'decimals'
      })
      const amountString = Big(amount).toString()
      const abi = chainId === 1 && toTokenSymbol === 'usdt'
        ? parseAbi(ABI)
        : erc20Abi
      const { request } = await simulateContract(wagmiConfig, {
        abi,
        address: toTokenAddress,
        functionName: 'transfer',
        args: [
          payee,
          parseUnits(amountString, decimals)
        ]
      })
      const hash = await writeContract(wagmiConfig, request)
      for (let i = 0; i < 5; ++i) {
        try {
          await delay(500)
          const tx = await getTransaction(wagmiConfig, { chainId, hash })
          return tx
        } catch (e) {
          console.warn(`getTransaction failed: ${hash}`)
          console.warn(e.message)
        }
      }

      return {
        hash
      }
    } catch (e) {
      console.warn('wallet (transfer): ', e)
      throw Error(e)
    }
  },
  async GET_TX_RECEIPT ({ state }, hash) {
    const chainId = parseInt(state.network.id)
    const receipt = await waitForTransactionReceipt(wagmiConfig, {
      chainId,
      hash,
      confirmations: 1
    })
    Log('GET_TX_RECEIPT', receipt)
    return receipt
  },
  async GET_TX_CONFIRMATIONS ({ state }, params) {
    const chainId = parseInt(state.network.id)
    const requestParams = {
      chainId
    }
    const { hash, transactionReceipt } = params
    if (params.transactionReceipt) {
      requestParams.transactionReceipt = transactionReceipt
    } else if (params.hash) {
      requestParams.hash = hash
    }
    const confirmations = await getTransactionConfirmations(wagmiConfig, requestParams)
    return confirmations
  },
  GET_TOKEN_INFO ({ commit, state, dispatch }, { tokenSymbol, chainId }) {
    return getTokenInfo(tokenSymbol, chainId)
  },
  async GET_TOKEN_BALANCE ({ state }, { token }) {
    if (token.toLowerCase() === 'eth' || token.toLowerCase() === 'bnb') {
      const balance = await getBalance(wagmiConfig, { address: state.address, chainId: state.network.id })
      return formatUnits(balance.value, balance.decimals)
    }
    const contract = TOKENS[state.network.id].find(el => el.symbol.toLowerCase() === token.toLowerCase())
    const result = await readContracts(wagmiConfig, {
      allowFailure: false,
      contracts: [{
        address: contract.address,
        abi: erc20Abi,
        functionName: 'decimals'
      }, {
        address: contract.address,
        abi: erc20Abi,
        functionName: 'balanceOf',
        args: [state.address]
      }]
    })
    const [decimals, balance] = result
    return formatUnits(balance, decimals)
  }
}
const getters = {
  ADDRESS: state => state.address,
  PAYER: state => state.address,
  IS_CONNECTED: state => state.isConnected,
  IS_LOGIN: state => state.isLogin,
  NETWORK: state => state.network,
  CHAIN_ID: state => state.network?.id,
  CHAIN_CURRENCY: state => state.network?.currency,
  TEMP_CHAIN_ID: state => state.tempTargetChain.id,
  TEMP_CHAIN_NAME: state => state.tempTargetChain.name,
  ACCOUNT: state => state.account,
  ALLOW_ACTIONS: state => {
    if (!state.walletName) {
      return {
        account: false,
        network: false
      }
    }
    return state.allowActions[state.walletName.toLowerCase()]
  },
  TX_HASH: state => state.txHash,
  TX_CURRENCY: state => state.txCurrency,
  CONFIG: state => state.config
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
